<template>
  <div>
    <!-- 导航栏 -->
    <div class="navbar">
      <div class="container">
        <div class="menu">
          <div class="left">
            <b-img
              class="logo"
              :src="require('@/assets/index/logo2.png')"
            ></b-img>
          </div>
          <div class="right">
            <div class="item">网站首页</div>
            <div class="item" @click="$router.push('/api')">API数据支持</div>
            <div class="item" @click="redirectToBaidu">业界动态</div>
          </div>
        </div>
      </div>
    </div>
    <!-- banner大图 -->
    <div class="banner">
      <div class="container">
        <div class="title">
          开放生态，助力商家<br />打通三方系统，实现数据互通
        </div>
        <div class="content">提供多场景优质能力,服务电商与开发者</div>
        <div class="btn" @click="$router.push('/api')">
          免费试用
          <b-img
            class="right"
            :src="require('@/assets/index/right.png')"
          ></b-img>
        </div>
      </div>
      <b-img
        class="bgi"
        :src="require('@/assets/index/banner.png')"
        fluid
        alt="Responsive image"
      ></b-img>
    </div>
    <!-- 介绍 -->
    <div class="introduce">
      <div class="container">
        <div class="title">我们的产品和服务</div>
        <div class="tabs">
          <div
            :class="['tab', { active: index == tabsIndex }]"
            v-for="(tabs, index) of tabs"
            :key="index"
            @click="tabsIndex = index"
          >
            {{ tabs.name }}
          </div>
        </div>
        <div>
          <div
            class="active-line"
            :style="{ transform: `translateX(${tabsIndex * 100}%)` }"
          ></div>
        </div>
      </div>
    </div>
    <div
      class="main"
      v-for="(tab, index) of tabs"
      :key="index"
      v-show="index == tabsIndex"
    >
      <div class="container">
        <div class="sub-title">{{ tab.name }}</div>
        <div class="text" v-html="tab.text"></div>
      </div>
      <b-img
        class="bgi"
        :src="require(`@/assets/index/intro${tabsIndex}.png`)"
        fluid
        alt="Responsive image"
      ></b-img>
    </div>
    <!-- 平台 -->
    <div class="platform">
      <div class="title">30+的监测平台</div>
      <b-img
        class="bgi"
        :src="require(`@/assets/index/platform.png`)"
        fluid
        alt="Responsive image"
      ></b-img>
    </div>
    <!-- 公司信息 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
export default {
  components: {
    Footer,
  },
  
  data() {
    return {
      tabsIndex: 0,
      tabs: [
        {
          name: "价格监控",
          text: `支持淘宝、天猫、京东、拼多多、等主流平台<br/>可查询热销SKU价格，销量<br/>通过关键词+筛选内容，精准定位商品`,
        },
        {
          name: "品牌营销",
          text: `支持淘宝、天猫、京东、拼多多、抖音等平台<br/>监测渠道、竞品市场价格和促销活动`,
        },
        {
          name: "消费探索",
          text: `市场全渠道销售表现洞察,属性精准数据分析<br/>市场未来趋势预测，发现市场新机会点`,
        },
        {
          name: "API定制",
          text: `让数据流动，赋能商业价值<br/>通过API直接获取商品数据<br/>需求定制化服务，7x24h毫秒级稳定调用`,
        },
      ],
    };
  },
 methods: {  
    redirectToBaidu() {  
      window.location.href = "https://www.lianxunshuju.com/news"; // 确保这是你的预期跳转URL  
    }  
  }
};

</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  height: 80px;
  background-color: #000000;
  position: sticky;
  top: 0;
  z-index: 9999;
  padding: 14px 1rem 19px;
  .container {
    .menu {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .logo {
          width: 107px;
          height: 47px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        .item {
          color: #ffffff;
          font-size: 1rem;
          margin-left: 2.1875rem;
          cursor: pointer;
          a {
            color: #ffffff;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
.banner {
  background-color: #000000;
  height: 580px;
  position: relative;
  .container {
    color: #ffffff;
    position: relative;
    z-index: 1;
    .title {
      padding-top: 7.5rem;
      font-size: 2.625rem;
      line-height: 5.125rem;
    }
    .content {
      font-size: 1.375rem;
    }
    .btn {
      margin-top: 100px;
      width: 287px;
      height: 55px;
      background: linear-gradient(90deg, #54cd52, #0ca750);
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 20px;
      .right {
        width: 8px;
        height: 14px;
      }
    }
  }
  .bgi {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.introduce {
  margin-top: 9.0625rem;
  .container {
    .title {
      text-align: center;
      color: #000000;
      font-size: 2.1875rem;
      margin-bottom: 65px;
    }

    .tabs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tab {
        flex: 1;
        color: #000000;
        font-size: 1rem;
        text-align: center;
        padding: 13px 0;
        cursor: pointer;
      }
      .active {
        color: #0ca750;
      }
    }
    .active-line {
      width: 25%;
      height: 3px;
      background-color: #0ca750;
      transform: translateX(0);
      transition: all 0.5s ease;
    }
  }
}
.main {
  position: relative;
  width: 100%;
  height: 440px;
  background-color: #000000;
  margin-top: 20px;
  .sub-title {
    position: relative;
    color: #ffffff;
    z-index: 1;
    font-size: 1.875rem;
    position: relative;
    padding-top: 120px;
    padding-bottom: 12px;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 121px;
      height: 6px;
      background: linear-gradient(90deg, #0ca750 0%, rgba(12, 167, 80, 0) 100%);
    }
  }
  .text {
    margin-top: 35px;
    position: relative;
    color: #ffffff;
    z-index: 1;
    line-height: 32px;
  }
  .bgi {
    width: 1150px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.platform {
  margin-top: 80px;
  .title {
    color: #000000;
    font-size: 2.1875rem;
    text-align: center;
    margin-bottom: 110px;
  }
}
</style>