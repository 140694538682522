// 所有的接口路由
const router = {
  'taobao': ['detail_info', 'detail_info_raw','keyword_search', 'coupon_info_v1_1', 'item_rate_v3', 'item_month_sale', 'shop_item_list', 'item_desc'
  ],
  'xianyu': ['keyword_search','item_info'],
  '1688': ['item_detail_info'],
  'jd': ['item_info_v1'],
}

// 每个模块下的默认路由
const defaultRouter = {
  'taobao': 'detail_info',
  'xianyu': 'keyword_search',
  '1688': 'item_detail_info',
  'jd': 'item_info_v1',
}

// 路由名称映射
const routerMap = [
  { 'taobao': '淘宝-天猫' },
  { 'xianyu': '闲鱼' },
  { '1688': '1688' },
  { 'jd': '京东' },
]

// api接口名称映射
const apiNameMap = {
  'taobao': {
    'detail_info': '淘宝商品详情信息',
    'detail_info_raw': '淘宝商品详情原始信息',
    'keyword_search': '淘宝商品关键词搜索',
    'coupon_info_v1_1': '淘宝商品优惠券信息v1.1',
    'item_rate_v3': '淘宝商品评论v3',
    'item_month_sale': '淘宝商品月销量',
    'shop_search': '淘宝店铺搜索',
    'shop_item_list': '淘宝店铺列表（全店商品）',
    'item_desc': '淘宝商品详情页面图片'
  },
  'xianyu': { 'keyword_search': '闲鱼商品关键词搜索', 'item_info':'闲鱼商品详情'},
  '1688': { 'item_detail_info': '1688商品详情' },
  'jd': { 'item_info_v1': '京东商品详情(app版v1)' },
}
export { router, defaultRouter, routerMap, apiNameMap }