<template>
  <div class="layout">
    <!-- 导航栏 -->
    <div class="navbar">
      <div class="container">
        <div class="menu">
          <div class="left">
            <b-img
              class="logo"
              :src="require('@/assets/index/logo2.png')"
            ></b-img>
            <div class="text">API文档</div>
          </div>
          <div class="right">
            <div class="item" @click="$router.push('/')">网站首页</div>
            <div class="item" @click="$router.push('/articleList')">业界动态</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 面包屑 -->
    <div class="breadcrumb container">
      <p @click="$router.push('/')">首页</p>
      <p>/</p>
      <p>API文档</p>
    </div>
    <div class="container pb-30">
      <div class="module">
        <b-nav pills class="b-nav">
          <b-nav-item
            v-for="(nav, index) in nav"
            :key="index"
            :active="Object.values(nav)[0] === moduleName"
            :to="Object.keys(nav)[0]"
            link-classes="b-nav-item"
            active-class="active"
            >{{ Object.values(nav)[0] }}</b-nav-item
          >
        </b-nav>
      </div>
      <div class="row main">
        <div class="col-sm-3 d-none d-sm-block api-item">
          <b-nav class="b-nav" vertical>
            <b-nav-item
              v-for="(value, index) in api"
              :key="index"
              :to="moduleNameEN + '_' + value"
              link-classes="b-nav-item"
              active-class="active"
            >
              <b-nav-text class="big-title">{{ value }}</b-nav-text>
              <br />
              <b-nav-text class="sub-title" v-if="apiNameMap[moduleNameEN]">{{
                apiNameMap[moduleNameEN][value]
              }}</b-nav-text>
            </b-nav-item>
          </b-nav>
        </div>
        <div class="col-sm-9 block">
          <router-view></router-view>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { router, routerMap, apiNameMap } from "@/assets/js/router.js";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Footer,
  },
  data() {
    return {
      nav: {},
      moduleName: "",
      moduleNameEN: "",
      api: [],
      apiNameMap: {},
    };
  },
  watch: {
    $route(to) {
      const name = to.name.split("_")[0];
      if (name == this.moduleNameEN) {
        return;
      }
      this.moduleNameEN = name;
      routerMap.forEach((value) => {
        if (Object.keys(value)[0] == name) {
          this.moduleName = value[name];
        }
      });
      this.nav = routerMap;
      let api = JSON.parse(JSON.stringify(router));
      this.api = api[name];
    },
  },
  created() {
    const name = this.$route.name.split("_")[0];
    this.moduleNameEN = name;
    routerMap.forEach((value) => {
      if (Object.keys(value)[0] == name) {
        this.moduleName = value[name];
      }
    });
    this.nav = routerMap;
    let api = JSON.parse(JSON.stringify(router));
    this.api = api[name];
    this.apiNameMap = apiNameMap;
  },
};
</script>

<style lang="scss" scoped>
.layout {
  background-color: #f2f2f2;
}
.pb-30 {
  padding-bottom: 30px;
}
.navbar {
  width: 100%;
  height: 80px;
  background-color: #000000;
  position: sticky;
  top: 0;
  z-index: 9999;
  padding: 14px 1rem 19px;
  .container {
    height: 100%;
    .menu {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        .logo {
          width: 107px;
          height: 47px;
        }

        .text {
          color: #0ca750;
          font-size: 1rem;
          margin-left: 25px;
          margin-bottom: 2px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        .item {
          color: #ffffff;
          font-size: 1rem;
          margin-left: 2.1875rem;
          cursor: pointer;
          a {
            color: #ffffff;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
.breadcrumb {
  background-color: transparent;
  font-size: 0.875rem;
  padding: 20px 16px;
  margin-bottom: 0;
  p {
    margin: 0;
    &:nth-child(1) {
      margin-left: 15px;
      color: #656565;
      cursor: pointer;
    }
    &:nth-child(2) {
      margin: 0 10px;
    }
    &:nth-child(3) {
      color: #0ca750;
    }
  }
}
.module {
  padding: 5px 0 13px;
  border-radius: 4px;
  background-color: #ffffff;
  .b-nav {
    .b-nav-item {
      background-color: #fff;
      color: #aaaaaa;
      height: 30px;
      font-size: 0.875rem;
      line-height: 15px;
      margin: 9px 16px;
      border-radius: 2px;
      border: 1px solid #bfbfbf;
      box-sizing: border-box;
      &:hover {
        background-color: #e3f7e3;
        color: #0ca750;
      }
    }
    .active {
      background-color: #e3f7e3;
      color: #0ca750;
      border: none;
    }
  }
}
.main {
  margin-top: 20px;
  .api-item {
    .b-nav {
      padding-top: 30px;
      height: 100%;
      background-color: #fff;
      .navbar-text {
        padding: 0;
        word-break: break-all;
      }
      .nav-item {
        &:hover {
          background-color: #eeeeee;
        }
      }
      .b-nav-item {
        width: 100%;
        padding-left: 1.5625rem;
        color: #000000;
        font-size: 0.875rem;

        .sub-title {
          color: #989898;
          font-size: 0.75rem;
        }
      }
      .active {
        background-color: #e3f7e3;
        color: #0ca750;
        position: relative;
        &::before {
          content: "";
          width: 5px;
          height: 100%;
          background-color: #0ca750;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }
  .block {
    @media (min-width: 576px) {
      padding-left: 0;
    }
  }
}
</style>