import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/Index.vue'
import Layout from '@/components/Layout.vue'
import handleRouter from '@/assets/js/getRouter'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Index,
    meta: {
      title: '联讯数据',
    },
  },
  {
    path: '/404',
    component: () => import('@/views/404'),
  },
  {
    path: '/api',
    name: 'api',
    meta: {
      title: '联讯数据',
    },
    component: Layout,
    redirect: '/api/taobao_detail_info'
  },
  {
    path: '/articleList',
    name: 'articleList',
    component: () => import('@/views/ArticleList.vue'),
  },
  {
    path: '/article/:id',
    name: 'article',
    component: () => import('@/views/Article.vue'),
  },
  { path: '*', redirect: '/404' }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

const routerList = handleRouter();
routerList.forEach(v => {
  router.addRoute('api', v);
})
// console.log(router.getRoutes());

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
router.afterEach((to, from) => {
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 75);
})
export default router
