<template>
  <div>
    <!-- 公司信息 -->
    <div class="info" id="info">
      <div class="container">
        <div class="left">
          <b-img class="img" :src="require('@/assets/index/logo1.png')"></b-img>
          <div>www.lianxunshuju.com</div>
        </div>
        <div class="center">
          <div class="item">
            <b-img class="img" :src="require('@/assets/index/tel.png')"></b-img>
            <div class="text">电话：19866750317</div>
          </div>
          <div class="item">
            <b-img
              class="img"
              :src="require('@/assets/index/weChat.png')"
            ></b-img>
            <div class="text">微信：luck626Y</div>
          </div>
          <div class="item">
            <b-img
              class="img"
              :src="require('@/assets/index/email.png')"
            ></b-img>
            <div class="text">邮件：3176474878@qq.com</div>
          </div>
        </div>
        <div class="right">
          <div class="item" v-b-modal.modal-1>
            <b-img class="img" :src="require('@/assets/index/ab.png')"></b-img>
            <div class="text">微信客服</div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <!-- <a href="https://beian.miit.gov.cn/" target="_blank">京公网安备 11010802020202号</a> -->
        <a href="https://beian.miit.gov.cn/" target="_blank">赣ICP备2023013732号</a>
      </div>
    </div>
    <!-- 图片预览 -->
    <b-modal
      class="modal"
      id="modal-1"
      centered
      title="BootstrapVue"
      hide-header
      hide-footer
    >
      <div class="justify-content-center">
        <b-img
          class="modal-img"
          :src="require('@/assets/index/ab.png')"
        ></b-img>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.info {
  background-color: #1d2c23;
  height: 350px;
  .container {
    display: flex;
    padding: 92px 0 0;
    justify-content: space-between;
    .left {
      background-image: url("@/assets/index/line.png");
      background-size: 1px 121px;
      background-repeat: no-repeat;
      background-position: right top;
      padding-right: 108px;
      .img {
        width: 139px;
        height: 63px;
      }
      div {
        &:nth-child(2) {
          color: #aaaaaa;
          font-size: 0.9375rem;
          margin-top: 10px;
        }
      }
    }
    .center {
      width: 360px;
      position: relative;
      background-image: url("@/assets/index/line.png");
      background-size: 1px 121px;
      background-repeat: no-repeat;
      background-position: right top;
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        .img {
          width: 20px;
          height: 20px;
        }
        .text {
          color: #c9c9c9;
          font-size: 1rem;
          margin-left: 20px;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      .item {
        margin-left: 20px;
        .img {
          width: 93px;
          height: 93px;
        }
        .text {
          color: #c9c9c9;
          font-size: 0.875rem;
          text-align: center;
          margin-top: 4px;
        }
      }
    }
  }
  .copyright {
    margin-top: 20px;
    text-align: center;
    a {
      color: #c9c9c9;
      font-size: 0.9375rem;;
      &:nth-child(1) {
        margin-right: 20px;
      }
    }
  }
}
#modal-1 {
  .justify-content-center {
    display: flex;
    .modal-img {
      width: 200px;
      height: 200px;
    }
  }
}
</style>