import { router, defaultRouter } from "./router.js";

function handleRouter() {
  let {keys, values} = Object;
  let arr = [];
  for (let key of keys(router)) {
    let obj1 = {
      path: '/api/' + key,
      name: key,
      redirect: '/api/' + key + '_' + defaultRouter[key],
      component: { render(c) { return c('router-view') } },
      children: [],
    }
    for (let value of values(router[key])) {
      let obj2 = {
        path: '/api/' + key + '_' + value,
        name: key + '_' + value,
        meta: {
          title: '淘宝接口在线文档',
        },
        component: () => import('@/views/Detail.vue')
      }
      obj1.children.push(obj2);
    }
    arr.push(obj1);
  }
  return arr;
}

export default handleRouter